import { SkipToken, skipToken } from '@reduxjs/toolkit/query/react';
import { ProductConfig, ProductOption } from 'src/types/ProductConfig';
import {
  ApplicantDetails,
  PaymentFrequency,
  PaymentMethod,
  PolicyDetails,
  QuoteSession,
} from 'src/types/QuoteSession';
import dateUtils from 'src/utils/dateUtils';
import emailQuoteUtils from 'src/utils/emailQuoteUtils';
import config from 'src/utils/env';
import { getQuoteSession } from 'src/utils/localStorageUtils';
import { getProductByCode } from 'src/utils/productUtils';
import {
  PriceApiApplicant,
  PriceApiBenefitResult,
  PriceApiBenefitResultFreqencies,
  PriceApiBrand,
  PriceApiFetchPriceRequest,
  PriceApiFetchPriceRequestV2,
  PriceApiFetchPriceResponse,
  PriceApiFetchPriceResponseV2,
  PriceApiGender,
  PriceApiPaymentMethod,
  PriceApiPolicy,
  PriceApiProduct,
  PriceApiProductBenefit,
  PriceApiProductBenefitV2,
} from './priceApiTypes';

const frequencyMapping: {
  [key in PaymentFrequency]: keyof PriceApiBenefitResultFreqencies;
} = {
  [PaymentFrequency.Weekly]: 'weekly',
  [PaymentFrequency.Fortnightly]: 'fortnightly',
  [PaymentFrequency.Monthly]: 'monthly',
  [PaymentFrequency.Quarterly]: 'quarterly',
  [PaymentFrequency.HalfYearly]: 'halfYearly',
  [PaymentFrequency.Yearly]: 'annual',
};

interface HospitalExcessOptionConfig {
  plansHaveDifferentExcessOptions: boolean;
  options: ExcessOption[];
}

interface ExcessOption {
  value: number;
  disabled: boolean;
}

export class PriceApiUtils {
  createFetchPriceRequest = (
    quoteSession: QuoteSession
  ): PriceApiFetchPriceRequest | SkipToken => {
    if (!quoteSession || quoteSession.completedPages.length === 0) {
      return skipToken;
    }
    const request: PriceApiFetchPriceRequest = {
      channel: 'DTC',
      brand: this.getPriceApiBrand(),
      billingDate:
        emailQuoteUtils.resumedQuoteBillingDate || dateUtils.getCurrentDate(),
      applicants: this.getPriceApiApplicants(quoteSession),
      payFrequency: 'Weekly', // This can be hard-coded ... always returns results for all frequencies
      paymentMethod: this.getPriceApiPaymentMethod(quoteSession),
    };
    return request;
  };

  getPriceApiApplicants = (quoteSession: QuoteSession): PriceApiApplicant[] => {
    return quoteSession.applicantDetails.map((a) => {
      const applicant: PriceApiApplicant = {
        id: a.id,
        dateOfBirth: dateUtils.getNominalBirthdateForAge(+a.age),
        isSmoker: this.getPriceApiSmoker(a),
        gender: this.getPriceApiGender(a),
        products: this.getPriceApiProducts(quoteSession),
      };
      return applicant;
    });
  };

  getPriceApiSmoker = (applicantDetails: ApplicantDetails): boolean => {
    return applicantDetails.smoker === 'Yes';
  };

  getPriceApiBrand = (): PriceApiBrand => {
    return config.brand.priceApiBrand;
  };

  getPriceApiPaymentMethod = (
    quoteSession: QuoteSession
  ): PriceApiPaymentMethod => {
    return quoteSession.paymentDetails.paymentMethod ===
      PaymentMethod.CreditCard
      ? 'CreditCard'
      : 'DirectDebit';
  };

  getPriceApiGender = (applicantDetails: ApplicantDetails): PriceApiGender => {
    if (applicantDetails.gender === 'Male') {
      return 'Male';
    }
    if (applicantDetails.gender === 'Female') {
      return 'Female';
    }
    throw new Error(
      `getPriceApiGender: Unsupported gender: ${applicantDetails.gender}`
    );
  };

  getPriceApiProducts = (quoteSession: QuoteSession): PriceApiProduct[] => {
    const isMember = this.isMember(quoteSession);
    const hospitalProducts = this.getHospitalProductConfig(isMember);
    const everydayProducts = this.getEverydayProductConfig(isMember);

    const planToBenefitsMap: Record<string, PriceApiProductBenefit[]> = {};
    everydayProducts.forEach((p) => {
      if (!planToBenefitsMap[p.productDetails.planCode]) {
        planToBenefitsMap[p.productDetails.planCode] = [];
      }
      planToBenefitsMap[p.productDetails.planCode].push({
        code: p.productDetails.code,
        type: 'Product',
      });
    });

    hospitalProducts.forEach((p) => {
      p.excesses?.forEach((excess) => {
        if (!planToBenefitsMap[p.productDetails.planCode]) {
          planToBenefitsMap[p.productDetails.planCode] = [];
        }
        planToBenefitsMap[p.productDetails.planCode].push({
          code: excess.code,
          type: 'Excess',
        });
      });
      p.nonPharmacPlusOptions?.forEach((addon) => {
        if (!planToBenefitsMap[p.productDetails.planCode]) {
          planToBenefitsMap[p.productDetails.planCode] = [];
        }
        planToBenefitsMap[p.productDetails.planCode].push({
          code: addon.code,
          type: 'EverydayAddon',
        });
      });
    });

    const products = Object.keys(planToBenefitsMap).map((planCode) => {
      return {
        code: planCode,
        benefits: planToBenefitsMap[planCode],
      } as PriceApiProduct;
    });

    return products;
  };

  isMember = (quoteSession: QuoteSession): boolean => {
    return quoteSession.hasMember === true;
  };

  getHospitalProductConfig(isMember: boolean): ProductConfig[] {
    const hospitalProducts = config.brand.productConfig.filter(
      (pc) => pc.productDetails.productType === 'Hospital'
    );
    if (config.brand.hasMemberProducts) {
      return hospitalProducts.filter(
        (p) => p.productDetails.isMemberProduct === isMember
      );
    }
    return hospitalProducts;
  }

  getEverydayProductConfig(isMember: boolean): ProductConfig[] {
    const everyDayProducts = config.brand.productConfig.filter(
      (pc) => pc.productDetails.productType === 'Everyday'
    );
    if (config.brand.hasMemberProducts) {
      return everyDayProducts.filter(
        (p) => p.productDetails.isMemberProduct === isMember
      );
    }
    return everyDayProducts;
  }

  getApplicantEverydayPrice = (
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    const policyDetails = this.getApplicantPolicyDetails(
      applicantId,
      quoteSession
    );
    if (!policyDetails.everydayProductCode) {
      return 0;
    }
    return this.getApplicantProductPrice(
      applicantId,
      policyDetails.everydayProductCode,
      quoteSession,
      priceData
    );
  };

  getApplicantPolicyDetails = (
    applicantId: string | null,
    quoteSession: QuoteSession
  ): PolicyDetails => {
    return quoteSession.getApplicantPolicyDetails(applicantId);
  };

  getTotalPlanPrice = (
    product: ProductConfig,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    let totalPrice = 0;
    for (const applicant of quoteSession.applicantDetails) {
      totalPrice += this.getApplicantPlanPrice(
        applicant.id,
        product,
        quoteSession,
        priceData
      );
    }
    return totalPrice;
  };

  getApplicantPlanPrice = (
    applicantId: string,
    product: ProductConfig,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    // For everyday products ... the price is based directly
    // on the product code.
    //
    let targetProductCode = product.productDetails.code;
    if (product.productDetails.productType === 'Hospital') {
      // For hospital products, we need to find the relevant
      // excess option to find the price.
      //
      const option = this.getEffectiveHospitalExcess(
        applicantId,
        product,
        quoteSession
      );
      targetProductCode = option.code;
    }
    return this.getApplicantProductPrice(
      applicantId,
      targetProductCode,
      quoteSession,
      priceData
    );
  };

  getApplicantProductPrice = (
    applicantId: string,
    productCode: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    const benefitResult = this.getBenefitResult(
      applicantId,
      productCode,
      priceData
    );
    return this.getBenefitPrice(
      benefitResult,
      quoteSession.paymentDetails.frequency
    );
  };

  getBenefitPrice = (
    benefitResult: PriceApiBenefitResult,
    paymentFrequency: PaymentFrequency
  ): number => {
    const priceFrequency = frequencyMapping[paymentFrequency];
    const price = benefitResult.frequencies[priceFrequency];
    return price;
  };

  getBenefitResult = (
    applicantId: string,
    productCode: string,
    priceData: PriceApiFetchPriceResponse
  ): PriceApiBenefitResult => {
    const results = priceData.applicantResults.find(
      (r) => r.id === applicantId
    );
    if (!results) {
      throw new Error(
        `getBenefitResult: No price results for applicant id: ${applicantId}`
      );
    }
    for (const productResult of results.productResults) {
      // productResult has a code property which is the plan code
      // However, we don't actually check this as we only store the product
      // code and they are all unique across plans anyway and sufficient to
      // uniquely identify a product.
      const benefitResult = productResult.benefitResults.find(
        (br) => br.code === productCode
      );
      if (benefitResult) {
        return benefitResult;
      }
    }
    throw new Error(
      `getBenefitResult: No benefitResult for product code: ${productCode}`
    );
  };

  getApplicantTotalPrice = (
    applicantDetails: ApplicantDetails,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    return (
      this.getApplicantHospitalExcessPrice(
        applicantDetails.id,
        quoteSession,
        priceData
      ) +
      this.getApplicantNonPharmacPlusPrice(
        applicantDetails.id,
        quoteSession,
        priceData
      ) +
      this.getApplicantEverydayPrice(
        applicantDetails.id,
        quoteSession,
        priceData
      )
    );
  };

  hasAnyCoverSelected = (quoteSession: QuoteSession): boolean => {
    return Object.values(quoteSession.memberPolicyDetails).some(
      (p) => !!p.hospitalProductCode || !!p.everydayProductCode
    );
  };

  getPolicyTotalPrice = (priceData: PriceApiFetchPriceResponse | undefined) => {
    const quoteSession = getQuoteSession();
    return this.getPolicyTotalPriceForQuoteSession(priceData, quoteSession);
  };

  getPolicyTotalPriceForQuoteSession = (
    priceData: PriceApiFetchPriceResponse | undefined,
    quoteSession: QuoteSession | null
  ) => {
    if (!priceData) {
      return 0;
    }
    let totalPrice = 0;
    if (!quoteSession) {
      return 0;
    }
    try {
      quoteSession.applicantDetails.forEach(
        (a) =>
          (totalPrice += this.getApplicantTotalPrice(
            a,
            quoteSession,
            priceData
          ))
      );
    } catch (_err) {
      return 0;
    }
    return totalPrice;
  };

  getApplicantHospitalExcessPrice = (
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    const productCode = this.getSelectedHospitalExcessProductCode(
      applicantId,
      quoteSession
    );
    if (!productCode) {
      return 0;
    }

    return this.getApplicantProductPrice(
      applicantId,
      productCode,
      quoteSession,
      priceData
    );
  };

  getApplicantNonPharmacPlusPrice = (
    applicantId: string,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    const policyDetails = this.getApplicantPolicyDetails(
      applicantId,
      quoteSession
    );
    if (+policyDetails.nonPharmacPlus === 0) {
      return 0;
    }
    const productCode = this.getSelectedNonPharmacPlusProductCode(
      applicantId,
      quoteSession
    );
    if (!productCode) {
      throw new Error(
        `getApplicantNonPharmacPlusPrice: can not find selected product`
      );
    }

    return this.getApplicantProductPrice(
      applicantId,
      productCode,
      quoteSession,
      priceData
    );
  };

  getSelectedHospitalExcessProductCode = (
    applicantId: string,
    quoteSession: QuoteSession
  ): string | null => {
    const option = this.getSelectedHospitalExcessProductOption(
      applicantId,
      quoteSession
    );
    if (option) {
      return option.code;
    }
    return null;
  };

  getSelectedHospitalExcessProductOption = (
    applicantId: string,
    quoteSession: QuoteSession
  ): ProductOption | null => {
    const selectedProductInfo = this.getSelectedHospitalProductConfig(
      applicantId,
      quoteSession
    );
    if (!selectedProductInfo) {
      return null;
    }

    return this.getEffectiveHospitalExcess(
      applicantId,
      selectedProductInfo,
      quoteSession
    );
  };

  getEffectiveHospitalExcess = (
    applicantId: string | null,
    product: ProductConfig,
    quoteSession: QuoteSession
  ): ProductOption => {
    const policyDetails = this.getApplicantPolicyDetails(
      applicantId,
      quoteSession
    );
    const selectedProductExcess =
      +policyDetails.productCodeToSelectedExcess[product.productDetails.code];
    let excess = product.excesses?.find(
      (e) => e.value === selectedProductExcess
    );
    if (!excess) {
      throw new Error(
        `getEffectiveHospitalExcess: could not find selected excess: ${selectedProductExcess} for product: ${product.productDetails.code}`
      );
    }
    // if (!excess) {
    //   excess = this.getNearestExcess(product.excesses!, +policyDetails.excess);
    // }

    return excess;
  };

  getSelectedNonPharmacPlusProductCode = (
    applicantId: string,
    quoteSession: QuoteSession
  ): string | undefined => {
    const policyDetails = this.getApplicantPolicyDetails(
      applicantId,
      quoteSession
    );
    const option = this.getNonPharmacPlusOption(
      applicantId,
      quoteSession,
      +policyDetails.nonPharmacPlus
    );

    return option?.code;
  };

  getApplicantNonPharmacPlusOptionPrice = (
    applicantId: string,
    value: number,
    quoteSession: QuoteSession,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    const option = this.getNonPharmacPlusOption(
      applicantId,
      quoteSession,
      value
    );
    if (!option) {
      return 0;
    }
    return this.getApplicantProductPrice(
      applicantId,
      option.code,
      quoteSession,
      priceData
    );
  };

  getSelectedHospitalProductConfig = (
    applicantId: string | null,
    quoteSession: QuoteSession
  ): ProductConfig | null => {
    const policyDetails = quoteSession.getApplicantPolicyDetails(applicantId);
    if (policyDetails.hospitalProductCode) {
      return getProductByCode(policyDetails.hospitalProductCode) || null;
    }
    return null;
  };

  getNonPharmacPlusOption = (
    applicantId: string,
    quoteSession: QuoteSession,
    value: number
  ): ProductOption | undefined => {
    const selectedProductInfo = this.getSelectedHospitalProductConfig(
      applicantId,
      quoteSession
    );
    if (!selectedProductInfo) {
      // No hospital product selected.  Probably means an interim render
      // of a hospital card or non pharmac plus option before it gets
      // removed after they user switched to Everyday only
      //
      return undefined;
    }
    const option = selectedProductInfo.nonPharmacPlusOptions?.find(
      (e) => e.value === value
    );
    if (!option) {
      throw new Error(
        `getNonPharmacPlusOption: can not find non Pharmac Plus option with value: ${value} for product: ${selectedProductInfo.productDetails.code}`
      );
    }

    return option;
  };

  getTotalNonPharmacPlusOptionPrice = (
    value: number,
    priceData: PriceApiFetchPriceResponse
  ): number => {
    let totalPrice = 0;
    for (const applicant of getQuoteSession()!.applicantDetails) {
      totalPrice += this.getApplicantNonPharmacPlusOptionPrice(
        applicant.id,
        value,
        getQuoteSession()!,
        priceData
      );
    }
    return totalPrice;
  };

  getHospitalExcessOptionConfig(
    quoteSession: QuoteSession,
    applicantId: string | null
  ): HospitalExcessOptionConfig | null {
    const selectedProduct = this.getSelectedHospitalProductConfig(
      applicantId,
      quoteSession
    );
    if (!selectedProduct) {
      // Generally when this function is called there should be a selected
      // hospital product.  However, if the user switches from hospital to
      // everyday only ... this can get called with no hospital product selected
      // ... as an interim step to the whole ChooseExcessStep component getting
      // removed from the DOM so don't complain.
      //
      return null;
    }
    const products = this.getHospitalProductConfig(this.isMember(quoteSession));
    const valueToProductsMap: { [value: number]: string[] } = {};
    let previousExcesses: ProductOption[] | undefined;
    let plansHaveDifferentExcessOptions = false;
    for (const product of products) {
      if (this.hasDifferentExcessValues(product.excesses, previousExcesses)) {
        plansHaveDifferentExcessOptions = true;
      }
      product.excesses?.forEach((option) => {
        if (valueToProductsMap[option.value]) {
          valueToProductsMap[option.value].push(product.productDetails.code);
        } else {
          valueToProductsMap[option.value] = [product.productDetails.code];
        }
      });
      previousExcesses = product.excesses;
    }
    const excessOptions = Object.keys(valueToProductsMap).map((value) => {
      const option: ExcessOption = {
        value: +value,
        disabled: !valueToProductsMap[+value].includes(
          selectedProduct.productDetails.code
        ),
      };
      return option;
    });
    return {
      plansHaveDifferentExcessOptions,
      options: excessOptions,
    };
  }

  hasDifferentExcessValues(
    excessesOne: ProductOption[] | undefined,
    excessesTwo: ProductOption[] | undefined
  ) {
    if (!excessesOne || !excessesTwo) {
      return false;
    }
    if (excessesOne.length !== excessesTwo.length) {
      return true;
    }
    for (let i = 0; i < excessesOne.length; i++) {
      if (excessesOne[i].value !== excessesTwo[i].value) {
        return true;
      }
    }
    return false;
  }

  // Begin V2 methods
  createFetchPriceRequestV2 = (
    quoteSession: QuoteSession
  ): PriceApiFetchPriceRequestV2 | SkipToken => {
    if (!quoteSession || quoteSession.completedPages.length === 0) {
      return skipToken;
    }
    const request: PriceApiFetchPriceRequestV2 = {
      source: 'public-website',
      brand: this.getPriceApiBrand(),
      distributionChannel: 'DTC',
      policies: this.getPoliciesForFetchPriceRequestV2(quoteSession),
    };
    return request;
  };

  getPoliciesForFetchPriceRequestV2 = (
    quoteSession: QuoteSession
  ): PriceApiPolicy[] => {
    const policy: PriceApiPolicy = {
      priceEffectiveDate:
        emailQuoteUtils.resumedQuoteBillingDate || dateUtils.getCurrentDate(),
      policyId: quoteSession.joinId || 'defaultPolicyId',
      productFamily: 'HEALTH',
      isHistoricalCalculation: false,
      paymentMethod: this.getPriceApiPaymentMethod(quoteSession),
      members: quoteSession.applicantDetails.map((applicant) => {
        return {
          memberId: applicant.id,
          age: parseInt(applicant.age),
          gender: applicant.gender as PriceApiGender,
          isSmoker: applicant.smoker === 'Yes',
          productBenefits: this.getApiProductBenefitsV2(quoteSession),
        };
      }),
    };

    return [policy];
  };

  getApiProductBenefitsV2 = (
    quoteSession: QuoteSession
  ): PriceApiProductBenefitV2[] => {
    const isMember = this.isMember(quoteSession);
    const hospitalProducts = this.getHospitalProductConfig(isMember);
    const everydayProducts = this.getEverydayProductConfig(isMember);

    const everydayBenefits = everydayProducts.map((h) => ({
      productCode: h.productDetails.pcatBenefit!.productCode,
      subProductCode: h.productDetails.pcatBenefit!.subProductCode,
      membershipCode: h.productDetails.pcatBenefit!.membershipCode,
      type: h.productDetails.pcatBenefit!.type,
      excess: h.productDetails.pcatBenefit!.excess,
    }));

    const hospitalBenefits = hospitalProducts.reduce(
      (acc: PriceApiProductBenefitV2[], val: ProductConfig) => {
        if (val.excesses) {
          const excesses = val.excesses.map((e) => ({
            productCode: e.pcatBenefit!.productCode,
            subProductCode: e.pcatBenefit!.subProductCode,
            membershipCode: e.pcatBenefit!.membershipCode,
            type: e.pcatBenefit!.type,
            excess: e.value,
          }));
          acc = acc.concat(excesses);
        }
        if (val.nonPharmacPlusOptions) {
          const nonPharmac = val.nonPharmacPlusOptions.map((e) => ({
            productCode: e.pcatBenefit!.productCode,
            subProductCode: e.pcatBenefit!.subProductCode,
            membershipCode: e.pcatBenefit!.membershipCode,
            type: e.pcatBenefit!.type,
            limits: e.pcatBenefit!.limits,
          }));
          acc = acc.concat(nonPharmac);
        }
        return acc;
      },
      []
    );

    return [...everydayBenefits, ...hospitalBenefits];
  };

  convertV2ResponseToV1 = (
    v2Response: PriceApiFetchPriceResponseV2
  ): PriceApiFetchPriceResponse => {
    const v1Response: PriceApiFetchPriceResponse = {
      brand: this.getPriceApiBrand(),
      channel: v2Response.distributionChannel,
      applicantResults: v2Response.policies[0].members.map((member) => {
        return {
          id: member.memberId,
          age: member.age,
          gender: member.gender,
          isSmoker: member.isSmoker,
          productResults: [
            {
              code: this.getPriceApiBrand(),
              benefitResults: member.productBenefits.map((benefit) => {
                return {
                  id: benefit.historicalBenefitCodeUsed,
                  code: this.findLegacyBenefitCode(benefit) || '',
                  frequencies: benefit.productBenefitPremiumWithGst,
                };
              }),
            },
          ],
          totalPremium: member.memberPremiumWithGst,
        };
      }),
    };
    return v1Response;
  };

  findLegacyBenefitCode(benefit: any) {
    const legacyBenefit = config.brand.productConfig.find(
      (pc) =>
        pc.productDetails.pcatBenefit?.productCode === benefit.productCode &&
        pc.productDetails.pcatBenefit?.membershipCode === benefit.membershipCode
    );

    if (benefit.excess !== undefined) {
      const matchingExcess = legacyBenefit?.excesses?.find(
        (e) => e.value === benefit.excess
      );
      return matchingExcess
        ? matchingExcess.code
        : legacyBenefit?.productDetails.code;
    }

    if (benefit.limits) {
      return legacyBenefit?.nonPharmacPlusOptions?.find((e) =>
        e.pcatBenefit?.limits?.some(
          (l) => l.amount === benefit.limits[0].amount
        )
      )?.code;
    }

    return legacyBenefit?.productDetails.code;
  }
  // End V2 methods
}

const priceApiUtils = new PriceApiUtils();
export default priceApiUtils;
